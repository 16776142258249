body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Asegúrate de que esta clase esté en tu archivo CSS global o en tu módulo CSS */
.datepicker-container {
  position: relative; /* Asegúrate de que el contenedor sea posicionado */
  z-index: 10; /* Valor bajo para que el DatePicker esté detrás de los dropdowns */
}

.datepicker {
  position: relative; /* Asegúrate de que el DatePicker sea posicionado */
  z-index: 20; /* Valor más alto para que el DatePicker esté sobre otros elementos */
}

.dropdown-menu {
  z-index: 30; /* Valor alto para asegurar que el dropdown esté sobre el DatePicker */
}
